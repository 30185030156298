import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../../core/services/backend/backend.service';
import {LaundryService} from '../../../core/services/laundry/laundry.service';

@Component({
  selector: 'laundry-mini',
  templateUrl: './laundry-mini.component.html',
  styleUrls: ['./laundry-mini.component.css']
})
export class LaundryMiniComponent implements OnInit {

  constructor(public backendService: BackendService, public laundryService: LaundryService) { }

  ngOnInit(): void {
  }

}

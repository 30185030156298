<a class="btn btn-success float-right" routerLink="/notes/new">New Note</a>
<div class="card-columns content-react">
  <div class="card content-react" *ngFor="let note of backendService.notes" style="margin-top: 10px;">
    <div class="card-body content-react">
      {{note.note}}
    </div>
    <div class="card-footer content-react" style="font-size: 9px;">
      <span [innerText]="calcDate(note.date_entered)"></span> by {{note.posted_by_func}}
      <span class="float-right"><button class="btn btn-link btn-sm" style="font-size: 9px; padding-top: 0px; margin-top: 0px;" (click)="markDone(note.pk);">(Mark Done)</button></span>
    </div>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendService} from '../../core/services/backend/backend.service';
import {WeatherService} from '../../core/services/weather/weather.service';
import {Roommate} from '../../core/interfaces/user/roommate';
import * as moment from 'moment';
import {TelegramService} from '../../core/services/bot/telegram.service';
import {LaundryService} from '../../core/services/laundry/laundry.service';

@Component({
  selector: 'app-laundry',
  templateUrl: './laundry.component.html',
  styleUrls: ['./laundry.component.css']
})
export class LaundryComponent implements OnInit, OnDestroy {

  constructor(public backendService: BackendService,
              public weatherService: WeatherService,
              public telegramService: TelegramService,
              public laundryService: LaundryService) { }

  washerRoommate?: Roommate;
  dryerRoommate?: Roommate;

  dryerTimerSpecific: boolean;
  washerTimerSpecific: boolean;

  ngOnInit(): void {
    this.weatherService.contentReactRender(this.weatherService.theTime);
    this.laundryService.setupLaundry();
    this.dryerRoommate = this.laundryService.dryerRoommate;
    this.washerRoommate = this.laundryService.washerRoommate;
    console.log(this.dryerRoommate);
    this.laundryService.onLaundry = true;
  }

  ngOnDestroy() {
    this.laundryService.onLaundry = false;
  }

  /**
  ngOnDestroy() {
    clearInterval(this.areWashDoneYet);
    clearInterval(this.areDryDoneYet);
  }
   **/

  roommateBgCalc(roommate: Roommate): string {
    if(roommate._check === true){
      return "bg-success"
    }else{
      return "bg-danger"
    }
  }

  outputDryerURL() {
    if(this.dryerRoommate == null){
      return "/assets/img/empty.png"
    }
    return this.dryerRoommate.urlAvatar;
  }

  outputWasherURL() {
    if(this.washerRoommate == null){
      return "/assets/img/empty.png"
    }
    return this.washerRoommate.urlAvatar;
  }

  isDryerDisabled(): boolean {
    if(this.dryerRoommate == null){
      return true;
    }
    return false;
  }

  isWasherDisabled() {
    if(this.washerRoommate == null){
      return true;
    }
    return false;
  }

  private setupWasher(): Roommate {
    var r = this.backendService.roommates;
    for (let i = 0; i < r.length; i++) {
      if(r[i].laundryWash){
        return r[i];
      }
    }
    return null;
  }
  private setupDryer(): Roommate {
    var r = this.backendService.roommates;
    for (let i = 0; i < r.length; i++) {
      if(r[i].laundryDryer){
        return r[i];
      }
    }
    return null;
  }



  loadWasher(roommate: Roommate) {
    this.backendService.laundrySetPerson(roommate, "washer", true);
    var reloadItem = setTimeout(() => {
      this.ngOnInit();
    },150);
  }

  loadDryer(roommate: Roommate) {
    this.backendService.laundrySetPerson(roommate, "dryer", true);
    var reloadItem = setTimeout(() => {
      this.ngOnInit();
    },150);
  }

  isDryerRunning(): string[] {
    if(this.dryerRoommate != null){
      if(this.dryerRoommate.laundryDryerDone != true){
        return ["spinning"];
      }else{
        return ["pulse"];
      }
    }
    return [""];
  }

  isWasherRunning(): string[] {
    if(this.washerRoommate != null){
      if(this.washerRoommate.laundryWasherDone != true){
        return ["spinning"];
      }else{
        return ["pulse"];
      }
    }
    return [""];
  }

  calcDryerTimer(): string {
    if(this.dryerRoommate != null){

      if(this.dryerTimerSpecific === true){
        var d = moment.duration((moment(this.dryerRoommate.laundryDryTime).add(this.laundryService.dryerHour,'h').add(this.laundryService.dryerMin,'m').unix() - moment().unix()), 'seconds');
        return d.hours()+":"+d.minutes()+":"+d.seconds()
      }

      return moment(this.dryerRoommate.laundryDryTime).add(this.laundryService.dryerHour,'h').add(this.laundryService.dryerMin,'m').fromNow();
    }
    return "Not Running";
  }
  calcWasherTimer(): string {
    if(this.washerRoommate != null){

      if(this.washerTimerSpecific === true){
        var d = moment.duration( (moment(this.washerRoommate.laundryWashTime).add(this.laundryService.washerHour,'h').add(this.laundryService.washerMin,'m').unix() - moment().unix()), 'seconds');
        return d.hours()+":"+d.minutes()+":"+d.seconds()
      }
      return moment(this.washerRoommate.laundryWashTime).add(this.laundryService.washerHour,'h').add(this.laundryService.washerMin,'m').fromNow();

    }
    return "Not Running";
  }

  washerTimerToggle() {
    if(this.washerTimerSpecific === true){
      this.washerTimerSpecific = false;
    }else{
      this.washerTimerSpecific = true;
    }
  }
  dryerTimerToggle() {
    if(this.dryerTimerSpecific === true){
      this.dryerTimerSpecific = false;
    }else{
      this.dryerTimerSpecific = true;
    }
  }

  releaseDryer() {
    this.backendService.laundrySetPerson(this.dryerRoommate, "dryer", false);
    var reloadItem = setTimeout(() => {
      this.ngOnInit();
    },150);
  }
  releaseWasher() {
    this.backendService.laundrySetPerson(this.washerRoommate, "washer", false);
    var reloadItem = setTimeout(() => {
      this.ngOnInit();
    },150);
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.css']
})
export class EmojiComponent implements OnInit {

  constructor() { }

  @Input() shorthand: string = "";
  @Input() id: string = "";
  emojiList: any = {
    ':cloud:': '&#x2601;',
    ':sunny:': '&#x2600;',
    ':city_sunset:': '&#x1f306;',
    ':moon:': '&#x1f319;',
    ':partly_sunny:': '&#x26c5;'
  };

  ngOnInit(): void {
    document.getElementById(this.id).innerHTML = this.emojiList[this.shorthand];
  }

}

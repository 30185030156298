import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoardComponent } from './pages/board/board.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import { SettingsComponent } from './pages/settings/settings.component';
import { EmojiComponent } from './core/helpers/emoji/emoji.component';
import { BoardMiniComponent } from './mixins/navigation/board-mini/board-mini.component';
import { ChoresComponent } from './pages/chores/chores.component';
import { LaundryComponent } from './pages/laundry/laundry.component';
import { LaundryMixinComponent } from './mixins/board/laundry-mixin/laundry-mixin.component';
import {QRCodeModule} from 'angularx-qrcode';
import { NotesComponent } from './pages/notes/notes.component';
import { NewNoteComponent } from './pages/notes/new-note/new-note.component';
import { NotesMiniComponent } from './mixins/navigation/notes-mini/notes-mini.component';
import { NotesMixinComponent } from './mixins/board/notes-mixin/notes-mixin.component';
import { LaundryMiniComponent } from './mixins/navigation/laundry-mini/laundry-mini.component';
import { MailComponent } from './pages/mail/mail.component';
import { WeatherComponent } from './pages/weather/weather.component';
import { MailMixinComponent } from './mixins/board/mail-mixin/mail-mixin.component';

@NgModule({
  declarations: [
    AppComponent,
    BoardComponent,
    SettingsComponent,
    EmojiComponent,
    BoardMiniComponent,
    ChoresComponent,
    LaundryComponent,
    LaundryMixinComponent,
    NotesComponent,
    NewNoteComponent,
    NotesMiniComponent,
    NotesMixinComponent,
    LaundryMiniComponent,
    MailComponent,
    WeatherComponent,
    MailMixinComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    QRCodeModule
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendService} from '../../core/services/backend/backend.service';
import {WeatherService} from '../../core/services/weather/weather.service';
import * as moment from 'moment';
import set = Reflect.set;

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, OnDestroy {

  constructor(public backendService: BackendService, public weatherService: WeatherService) { }

  repeat: number = 0;
  invFunc: any;

  ngOnInit(): void {
    console.log("Recreated");
    this.fetchNotesHere();
  }

  ngOnDestroy() {
    console.log("Close out InvFunc");
  }

  fetchNotesHere() {
    this.backendService.fetchNotes();
    this.invFunc = setTimeout( () => {
      this.weatherService.contentReactRender(this.weatherService.theTime);
    }, 100);
  }

  calcDate(dateEntered: string) {
    return moment(dateEntered).fromNow();
  }

  markDone(pk: number) {
    this.backendService.markNoteDone(pk);
    this.fetchNotesHere();
  }
}

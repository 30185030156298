import {
  ChangeDetectionStrategy,
  Component, OnDestroy,
  OnInit
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BackendService} from './core/services/backend/backend.service';
import * as moment from 'moment';
import {WeatherService} from './core/services/weather/weather.service';
import {TelegramService} from './core/services/bot/telegram.service';
import {LaundryService} from './core/services/laundry/laundry.service'; // using @types/moment

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'control';
  links = [
    { title: 'Board', fragment: 'board', mixin: 'board' },
    { title: 'Chores', fragment: 'chores', mixin: 'chores' },
    { title: 'Notes', fragment: 'notes', mixin: 'notes' },
    { title: 'Laundry', fragment: 'laundry', mixin: 'laundry' },
    { title: 'Mail', fragment: 'mail', mixin: 'mail'},
    { title: 'Weather', fragment: 'weather', mixin: 'weather'},
    { title: 'Settings', fragment: 'settings', mixin: 'settings' }
  ];
  mainClockFunction: any;
  mainClock: string;

  mainTempFunction: any;
  private refreshRoommatesFunction: any;
  constructor(public route: ActivatedRoute, httpClient: HttpClient, public backendService: BackendService,
              public weatherService: WeatherService, public telegramService: TelegramService,
              public laundryService: LaundryService) {
  }

  ngOnInit() {

    // global timers.

    this.mainClock = moment(new Date()).format("HH:mm MM/DD");
    this.weatherService.fetchMetar();
    this.mainClockFunction = setInterval(() => {
      this.mainClock = moment(new Date()).format("HH:mm MM/DD");
    }, 1000);
    this.refreshRoommatesFunction = setInterval( () => {
      this.backendService.refreshRoommates();
    }, 900000)
    this.mainTempFunction = setInterval( () => {
      this.weatherService.fetchMetar();
    }, 900000);
    this.mainTempFunction = setInterval( () => {
      this.laundryService.setupLaundry();
    }, 900000);
    var nx = setTimeout(() => {
      this.laundryService.setupLaundry();
    },100);

  }

  ngOnDestroy() {
  }

  renderStatements() {
    if(this.weatherService.rainStatement === undefined){
      return this.weatherService.temperature + "F and " + this.weatherService.metar.rawData.textDescription
    }
    return this.weatherService.temperature + "F, " + this.weatherService.metar.rawData.textDescription
  }
}

<div class="row">
  <div *ngFor="let roommate of backendService.roommates; index as i" class="col-md-3" style="margin-top: 10px;">
    <div class="card" [classList]="roommateBgCalc(roommate)" style="border-radius: 5px 5px 5px 5px" *ngIf="i < 4">
      <img src="{{roommate.urlAvatar}}" class="card-img-top" style="margin-top: 15px;">
      <div class="card-body" style="padding: 0px;">
        <button class="btn btn-link btn-block text-white" [innerText]="roommateButtonText(roommate)" (click)="checkInOut(roommate)"></button>
      </div>
      <div class="card-footer text-center text-white" [innerText]="lastInCalc(roommate.lastIn)">
        {{roommate.lastIn}}
      </div>
    </div>
  </div>
  <div *ngFor="let roommate of backendService.roommates; index as o" class="col-md-12" style="margin-top: 10px;">
    <div class="card" [classList]="roommateBgCalc(roommate)" *ngIf="o > 3">
      <div class="card-body">
    <div class="row">
    <div class="col-md-2">
      <img [src]="roommate.urlAvatar" style="height: 75px; width: 75px; border-radius: 25px;">
    </div>
    <div class="col-md-10">
      <button class="btn btn-link btn-block text-white" [innerText]="roommateButtonText(roommate)" (click)="checkInOut(roommate)"></button>
      <div class="card-footer text-center text-white" [innerText]="lastInCalc(roommate.lastIn)">
        {{roommate.lastIn}}
      </div>
    </div>
    </div>
      </div>
    </div>

  </div>
</div>

<mail-mixin></mail-mixin>
<laundry-mixin></laundry-mixin>
<div class="row" style="margin-top: 20px">
  <div class="col-md-6"><app-notes-mixin *ngIf="backendService.notes != undefined"></app-notes-mixin></div>
</div>


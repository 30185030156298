<h2 class="text-center content-react" style="font-weight: lighter; margin-top: 10px;">Ctrl Version 1.8.0 <small>by yuukari</small></h2>
<div class="row" style="margin-top: 15px;">
  <div class="col-md-6">
      <button class="btn btn-warning btn-block" (click)="refreshApp()">Refresh Application</button>
  </div>
  <div class="col-md-6">
    <dl>
      <dt>Weather Station</dt>
      <dd>KGAI</dd>
    </dl>
  </div>
</div>

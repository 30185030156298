import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../core/services/backend/backend.service';
import {Roommate} from '../../core/interfaces/user/roommate';
import * as moment from 'moment';
import {WeatherService} from '../../core/services/weather/weather.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  constructor(public backendService: BackendService, public weatherService: WeatherService) { }

  ngOnInit(): void {
    var func = setTimeout( () => {
      this.weatherService.contentReactRender(this.weatherService.theTime);
    }, 100);
  }

  roommateBgCalc(roommate: Roommate): string {
    if(roommate._check === true){
      return "card bg-success"
    }else{
      return "card bg-danger"
    }
  }

  lastInCalc(lastIn: string): string {
    return moment(lastIn).fromNow()
  }

  roommateButtonText(roommate: Roommate) {
    if(roommate._check === true){
      return roommate.name+" is In"
    }else{
      return roommate.name+" is Out"
    }
  }

  checkInOut(roommate: Roommate) {
    this.backendService.checkInOut(roommate);
  }

  convert(degree,value) {
    var x;
    if (degree == "C") {
      x = value * 9 / 5 + 32;
    } else {
      x = (value -32) * 5 / 9;
    }
    return Math.round(x);
  }


}

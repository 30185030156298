<div class="row">
  <div class="col-md-2">
    <div class="card content-react">
      <a href="javascript:///" (click)="viewChore()"><img src="/assets/img/BuddyPress-Logo.png" class="card-img-top"></a>
      <div class="card-body">
        <button (click)="viewChore()" class="btn btn-block btn-link text-white">All</button>
      </div>
    </div>


  </div>
  <div class="col-md-2" *ngFor="let roommate of backend.roommates">
    <div class="card content-react">
        <a href="javascript:///" (click)="viewChore(roommate.pk, roommate)"><img src="{{roommate.urlAvatar}}" class="card-img-top"></a>
      <div class="card-body">
        <button (click)="viewChore(roommate.pk, roommate)" class="btn btn-block btn-link text-white">{{roommate.name}}</button>
      </div>
    </div>

  </div>
</div>
  <hr>

  <div *ngIf="selectRoommate.length === 0">
      <h3>Team House Chores <a href="javascript:///" (click)="loadChores()" style="color: gray; font-weight: lighter">(refresh)</a></h3>
  </div>
  <div *ngIf="selectRoommate.length != 0">
    <h3>{{selectRoommate[0].name}} <a href="javascript:///" (click)="loadChores()" style="color: gray; font-weight: lighter">(refresh)</a></h3>
  </div>

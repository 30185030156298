import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Roommate} from '../../interfaces/user/roommate';
import {HttpClient} from '@angular/common/http';
import {Metar} from '../../interfaces/weather/metar';
import {Router} from '@angular/router';
import {Note} from '../../interfaces/note/note';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private httpClient: HttpClient, private router: Router) {
    this.refreshRoommates();
  }

  public roommates: Roommate[];
  public notes: Note[];
  public activeEditNote: Note;
  public metar: Metar;
  public familyFriendly = false;

  toggleFamilyFriendly(){
    if(this.familyFriendly === true){
      this.familyFriendly = false;
    }else{
      this.familyFriendly = true;
    }
    this.refreshRoommates();
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: false, replaceUrl: true}).then(()=>
      this.router.navigate([uri]));
  }

  refreshRoommates(): void{
    this.httpClient.get<Roommate[]>(environment.baseUrl + 'api/roommates/?format=json')
      .subscribe(data => {
        this.roommates = data;
        if(this.familyFriendly){
          for (let i = 0; i < this.roommates.length; i++) {
            this.roommates[i].name = this.roommates[i].familyFriendlyName;
            this.roommates[i].urlAvatar = this.roommates[i].familyFriendlyAvatar;
          }
        }
      });
  }
  checkInOut(roommate: Roommate): void {
    this.httpClient.get<Roommate>(environment.baseUrl + 'controllers/check/'+roommate.pk+'/')
      .subscribe(data => {
        this.refreshRoommates();
      });
  }

  fetchNotes(): void{
    this.httpClient.get<Note[]>(environment.baseUrl + 'api/notes/?format=json')
      .subscribe(data => {
        this.notes = data;
      });
  }
  publishActiveEditNote(): void{
    this.httpClient.get<Note>(environment.baseUrl + 'api/notes/'+this.activeEditNote.pk+'/?format=json')
      .subscribe(data => {
        if(data.finished_creation === true){
          this.activeEditNote = data;
          this.redirectTo("/notes");
        }
      })
  }

  laundrySetPerson(roommate: Roommate, item: string, bool: boolean): void {
    this.httpClient.get(environment.baseUrl + 'controllers/laundry/'+roommate.pk+'/'+item+'/'+bool+'/')
      .subscribe(data => {
        this.refreshRoommates();
      })
  }

  public markNoteDone(pk: number): void{
    this.httpClient.get<Note>(environment.baseUrl + 'controllers/mark/note/'+pk+'/')
      .subscribe(data => {
          return "Yay";
      })
  }

  newNote(): void{
    console.log("Making a new note")
    let n: Note = new class implements Note {
      date_entered: string;
      done: boolean;
      finished_creation: boolean;
      note: string;
      pk: number;
      posted_by: Roommate;
      url: string;
    }
    n.finished_creation = false;
    n.done = false;
    n.url = null;
    n.note = "";
    n.date_entered = null;
    this.httpClient.post<Note>(environment.baseUrl + 'api/notes/?format=json', n)
      .subscribe(data => {
        this.activeEditNote = data;
      });
  }




}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private http: HttpClient
  ) { }

  get(uri: string): Observable<any>{
    return this.http.get(environment.baseUrl+'api/'+uri)
  }

  post(uri: string, data: any): Observable<any>{
    return this.http.post(environment.baseUrl+'api/'+uri, data)
  }

  patch(uri: string, data: any): Observable<any>{
    return this.http.patch(environment.baseUrl+'api/'+uri, data)
  }

}

import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../../core/services/backend/backend.service';
import {WeatherService} from '../../../core/services/weather/weather.service';
import {Roommate} from '../../../core/interfaces/user/roommate';

@Component({
  selector: 'mail-mixin',
  templateUrl: './mail-mixin.component.html',
  styleUrls: ['./mail-mixin.component.css']
})
export class MailMixinComponent implements OnInit {

  constructor(public backendService: BackendService, public weatherService: WeatherService) { }

  ngOnInit(): void {
  }

}

<div id="weathercasts">
  <div id="weather-0" class="weather" [innerHTML]="weatherService.setCloudLayer(0)">

  </div>
  <div id="weather-1" class="weather" [innerHTML]="weatherService.setCloudLayer(1)">

  </div>
  <div id="weather-2" class="weather" [innerHTML]="weatherService.setCloudLayer(2)">

  </div>
  <div id="weather-3" class="weather" [innerHTML]="weatherService.setCloudLayer(3)">

  </div>
</div>
<div class="container content-react" id="content">

    <div class="content-react">
      <div class="row content-react" style="margin-top: 2%">
        <div class="col-md-4 content-react">
            <h1 class="content-react">10523 <small style="font-weight: lighter">Polk Square Court</small></h1>
        </div>
        <div class="col-md-5 text-center">
          <h1><app-emoji [id]="'intf-front'" id="intf-front" [shorthand]="this.weatherService.emoji"></app-emoji> <span class="text-center" style="font-weight: lighter" [innerText]="renderStatements()">{{this.weatherService.temperature}} {{this.weatherService.metar.rawData.textDescription}}</span></h1>

        </div>
        <div class="col-md-3">
            <h1 style="font-weight: lighter;" class="float-right content-react" [innerText]="mainClock"></h1>
        </div>
      </div>
    </div>
    <div class="row content-react">
      <div class="col-md-2">
        <div class="nav flex-column nav-pills" ngbNav [activeId]="route.fragment" style="margin-top: 10px;">
          <li [ngbNavItem]="links[0].fragment">
            <a ngbNavLink routerLink="/{{links[0].fragment}}" style="padding-top: 20px; padding-bottom: 20px; font-size: 24px; font-weight: lighter">{{ links[0].title }}<br><board-mini></board-mini></a>
          </li>
          <li [ngbNavItem]="links[1].fragment">
            <a ngbNavLink routerLink="/{{links[1].fragment}}" style="padding-top: 20px; padding-bottom: 20px; font-size: 24px; font-weight: lighter">{{ links[1].title }}</a>
          </li>
          <li [ngbNavItem]="links[2].fragment">
            <a ngbNavLink routerLink="/{{links[2].fragment}}" style="padding-top: 20px; padding-bottom: 20px; font-size: 24px; font-weight: lighter">{{ links[2].title }} <notes-mini></notes-mini></a>
          </li>
          <li [ngbNavItem]="links[3].fragment">
            <a ngbNavLink routerLink="/{{links[3].fragment}}" style="padding-top: 20px; padding-bottom: 20px; font-size: 22px; font-weight: lighter">{{ links[3].title }} <laundry-mini></laundry-mini></a>
          </li>
          <li [ngbNavItem]="links[4].fragment">
            <a ngbNavLink routerLink="/{{links[4].fragment}}" style="padding-top: 20px; padding-bottom: 20px; font-size: 24px; font-weight: lighter">{{ links[4].title }}</a>
          </li>
          <li [ngbNavItem]="links[5].fragment">
            <a ngbNavLink routerLink="/{{links[5].fragment}}" style="padding-top: 20px; padding-bottom: 20px; font-size: 24px; font-weight: lighter">{{ links[5].title }}</a>
          </li>
          <li [ngbNavItem]="links[6].fragment">
            <a ngbNavLink routerLink="/{{links[6].fragment}}" style="font-weight: lighter; font-size: 24px;">{{ links[6].title }}</a>
          </li>

        </div>
      </div>
      <div class="col-md-10 content-react">
        <router-outlet></router-outlet>
      </div>
    </div>





</div>
<button (click)="backendService.toggleFamilyFriendly()" class="btn btn-link" style="color: transparent !important;">owo</button>

import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {BackendService} from '../../../core/services/backend/backend.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notes-mixin',
  templateUrl: './notes-mixin.component.html',
  styleUrls: ['./notes-mixin.component.css']
})
export class NotesMixinComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = false;

  constructor(config: NgbCarouselConfig, public backendService: BackendService) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
  }
  ngOnInit(): void {
    console.log(this.backendService.notes);
  }

  calcMoment(date_entered: string) {
    return moment(date_entered).fromNow();
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendService} from '../../core/services/backend/backend.service';
import {WeatherService} from '../../core/services/weather/weather.service';
import {TelegramService} from '../../core/services/bot/telegram.service';
import {Roommate} from '../../core/interfaces/user/roommate';
import {RestService} from '../../core/services/rest/rest.service';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent implements OnInit, OnDestroy {

  constructor(public backendService: BackendService,
              public weatherService: WeatherService,
              public botService: TelegramService,
              public restService: RestService) {

  }

  ngOnInit(): void {
    var x = setTimeout( () => {
      this.weatherService.contentReactRender(this.weatherService.theTime);
    }, 150);
  }

  ngOnDestroy() {
  }

  hasMail(roommate: Roommate) {
    if(roommate.hasMail === true){
      return "btn btn-success btn-block"
    }else{
      return "btn btn-danger btn-block"
    }
  }

  hasMailText(roommate: Roommate) {
    if(roommate.hasMail === true){
      return roommate.name+" has Mail."
    }else{
      return roommate.name+" does not have Mail."
    }
  }

  toggleMail(roommate: Roommate) {
    if(roommate.hasMail){
      this.restService.patch("roommates/"+roommate.pk+"/", {hasMail: false})
        .subscribe(
          (res) => {
            this.backendService.refreshRoommates();
            var x = setTimeout( () => {
              this.weatherService.contentReactRender(this.weatherService.theTime);
            }, 150);
          },
          error => {
            console.log(error);
          }
        )
    }else{
    this.botService.sendMessage("@"+roommate.handle+" you have mail. Please come pick it up and mark that you've gotten it on the board.");
    this.restService.patch("roommates/"+roommate.pk+"/", {hasMail: true})
      .subscribe(
        (res) => {
          this.backendService.refreshRoommates();
          var x = setTimeout( () => {
            this.weatherService.contentReactRender(this.weatherService.theTime);
          }, 150);
        },
        (err) => {
          console.log(err);
        }
      )
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendService} from '../../../core/services/backend/backend.service';
import {WeatherService} from '../../../core/services/weather/weather.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.css']
})
export class NewNoteComponent implements OnInit, OnDestroy {

  constructor(public backendService: BackendService, public weatherService: WeatherService) { }

  theEnvironment: any;

  checkFunc: any;

  ngOnInit(): void {
    this.backendService.activeEditNote = null;
    this.backendService.newNote();
    this.theEnvironment = environment;
    this.checkFunc = setInterval(() => {
      this.backendService.publishActiveEditNote();
    }, 2000)
  }

  ngOnDestroy(): void {
    clearInterval(this.checkFunc);
  }


}

<h4 *ngIf="backendService.notes.length > 0">Recent Notes</h4>
<ngb-carousel [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
  <ng-template ngbSlide *ngFor="let note of backendService.notes">
    <div class="picsum-img-wrapper">
      <div class="card content-react">
        <div class="card-body text-center" style="padding-bottom: 0px;">
          <p [innerText]="note.note"></p>
        </div>
        <div class="card-footer text-center" style="font-size: 11px">
          <span [innerText]="calcMoment(note.date_entered)"></span> by {{note.posted_by_func}}
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>

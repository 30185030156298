// BEFORE YOU DO ANYTHING ELSE, COPY THIS FILE INTO A FILE NAMED environment.prod.ts AND environment.ts IN THE SAME FOLDER.

export const environment = {
  production: true, // if false, build will include devDependencies and other angular dev tools.
  baseUrl: 'https://ctrl.be.yuu.im/', // The base URL for API calls. Trailing / required.
  houseNumber: '10523', // House, Apartment, or other Identifier
  houseStreet: 'Polk Square Court', // Further Identifier for UI
  lat: '', // Latitude of Location for Sun Data
  long: '', // Longitude of Location for Sun Data
  nearestAirport: 'KDCA', // Nearest Airport for METAR weather data
  chores: true, // If true, Chore Board is activated
  calendar: true, // If true, Calendar is activated
  demonym: 'Team House', // Demonym for people in your house or apartment. Used for All-house chore assignments.
  telegramBotToken: 'yourtokenhere', // Telegram Bot Token for Reminders
  telegramGroupChatId: 'yourgroupidhere', // Your family/house/apartment group for Reminders
  useTelegram: false // Global variable to enable telegram integration or disable.
};

import { Injectable } from '@angular/core';
import {BackendService} from '../backend/backend.service';
import {Metar} from '../../interfaces/weather/metar';
import {HttpClient} from '@angular/common/http';
import * as parseMETAR from 'metar';
import * as moment from 'moment';
import {tryCatch} from 'rxjs/internal-compatibility';
import {Sun} from '../../interfaces/weather/sun';
import {SunContainer} from '../../interfaces/weather/sun-container';
@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private backendService: BackendService, private httpClient: HttpClient) {
    this.fetchMetar();
  }

  public metar: Metar;

  public cloudStatement: string;
  public rainStatement: string;
  public emoji: string;
  public sunInformation: Sun;
  public temperature: string;
  public theTime: string;

  // https://api.weather.gov/stations/KTTN/observations/latest

  fetchMetar(): void{
    this.httpClient.get<any>("https://api.weather.gov/stations/KGAI/observations/latest", {headers: {'User-Agent': '(custom app, yuuisim@gmail.com)'}})
      .subscribe(data => {
        console.log(data);
        this.httpClient.get<SunContainer>("https://api.sunrise-sunset.org/json?lat=39.168333&lng=-77.166&date=today&formatted=0")
          .subscribe(data2 => {
            this.sunInformation = data2.results;
            this.metar = {metar: data.properties.rawMessage, rawData: data.properties} as Metar;
            console.log(parseMETAR(this.metar.metar));
            this.theTime = this.setTimeOfDay(data2.results);
            this.weatherScene(this.theTime);
          })
      })
  }

  setElementsClasses(arrayClasses: HTMLCollectionOf<Element>, newClass: string){
    for (let i = 0; i < arrayClasses.length; i++) {
      arrayClasses.item(i).setAttribute("class",newClass)
    }
  }

  removeClassFromElements(arrayClasses: HTMLCollectionOf<Element>, classes: string[]){
    for (let i = 0; i < arrayClasses.length; i++) {
      var x = arrayClasses.item(i).classList;
      for (let j = 0; j < classes.length; j++) {
        x.remove(classes[j]);
      }
    }
  }

  addClassesToId(elem: string, classes: string[]){
    for (let i = 0; i < classes.length; i++) {
      document.getElementById(elem).classList.add(classes[i]);
    }

  }
  addClassesToClass(elem: string, classes: string[]){
    var x = document.getElementsByClassName(elem);
    for (let i = 0; i < x.length; i++) {
      for (let j = 0; j < classes.length; j++) {
        x.item(i).classList.add(classes[j]);
      }
    }

  }

  setTimeOfDay(sunInfo: Sun){

    var thetime = "";



    var sunrise = moment(sunInfo.sunrise, 'YYYY-MM-DDTHH:mm:ssZZ');
    var sunset = moment(sunInfo.sunset, 'YYYY-MM-DDTHH:mm:ssZZ');
    var noon = moment(sunInfo.solar_noon, 'YYYY-MM-DDTHH:mm:ssZZ');

    var now = moment();
    thetime = "day";
    if(now.isAfter(moment().set('hour', 0).set('minute',0).set('second',0)) && now.isBefore((sunrise.subtract(1,'h')))){
      thetime = "night";
    }

    sunrise = moment(sunInfo.sunrise, 'YYYY-MM-DDTHH:mm:ssZZ');
    sunset = moment(sunInfo.sunset, 'YYYY-MM-DDTHH:mm:ssZZ');
    noon = moment(sunInfo.solar_noon, 'YYYY-MM-DDTHH:mm:ssZZ');

    now = moment();
    if(now.isAfter(sunrise.subtract(1,'h')) && now.isBefore(sunrise.add(5, 'h'))){
      thetime = "sunrise";
    }

    sunrise = moment(sunInfo.sunrise, 'YYYY-MM-DDTHH:mm:ssZZ');
    sunset = moment(sunInfo.sunset, 'YYYY-MM-DDTHH:mm:ssZZ');
    noon = moment(sunInfo.solar_noon, 'YYYY-MM-DDTHH:mm:ssZZ');

    now = moment();
    if(now.isAfter(sunrise.add(4,'h')) && now.isBefore(sunset.subtract(5,'h'))){
      thetime = "day";
      console.log("Sure, day.");
    }

    sunrise = moment(sunInfo.sunrise, 'YYYY-MM-DDTHH:mm:ssZZ');
    sunset = moment(sunInfo.sunset, 'YYYY-MM-DDTHH:mm:ssZZ');
    noon = moment(sunInfo.solar_noon, 'YYYY-MM-DDTHH:mm:ssZZ');

    now = moment();
    if(now.isAfter(sunset.subtract(1,'h')) && now.isBefore(sunset.add(2,'h'))){
      thetime = "sunset";
      console.log("Sure, sunset.");
    }

    sunrise = moment(sunInfo.sunrise, 'YYYY-MM-DDTHH:mm:ssZZ');
    sunset = moment(sunInfo.sunset, 'YYYY-MM-DDTHH:mm:ssZZ');
    noon = moment(sunInfo.solar_noon, 'YYYY-MM-DDTHH:mm:ssZZ');

    now = moment();
    if(now.isAfter(sunset.add(1,'h')) && now.isBefore(moment().set('hour',23).set('minute',59).set('second',59))){
      thetime = "night";
      console.log("Sure, night PM.");
    }

    return thetime;


  }

  weatherScene(thetime: string): void {
    let wswitch: string = "clear";
    document.getElementById("body").setAttribute("class", "");
    this.setElementsClasses(document.getElementsByClassName("weather"), "weather");
    this.removeClassFromElements(document.getElementsByClassName("content-react"), ["bg-dark", "text-white"]);
    var parsedMetar = parseMETAR(this.metar.metar);
    this.cloudStatement = "Clear";
    this.emoji = ":moon:";
    if(thetime === "day"){
      this.cloudStatement = "Sunny";
      this.emoji = ":sunny:";
    }
    if(thetime === "sunset"){
      this.cloudStatement = "Clear";
      this.emoji = ":city_sunset:";
    }
    for (let i = 0; i < parsedMetar.clouds.length; i++) {
      switch(parsedMetar.clouds[i].abbreviation){

        case "FEW":
          this.cloudStatement = "Partly Cloudy";
          this.emoji = ":partly_sunny:";
          if(thetime === "night"){
            this.emoji = ":moon:";
          }
          wswitch = "cloudy";
          break;
        case "SCT":
          this.cloudStatement = "Scattered Clouds";
          this.emoji = ":partly_sunny:";
          if(thetime === "night"){
            this.emoji = ":cloud:";
          }
          wswitch = "cloudy";
          break;
        case "OVC":
          this.cloudStatement = "Overcast";
          this.emoji = ":cloud:";
          wswitch = "cloudy";
          break;
        case "CLR":
          break;
        default:
          this.cloudStatement = "Cloudy";
          this.emoji = ":cloud:";
          wswitch = "cloudy";
          break;
      }

      this.addClassesToId("body", ["bg-"+thetime+"-"+wswitch]);
      this.contentReactRender(thetime);
      this.temperature = this.convert("C", parseMETAR(this.metar.metar).temperature) +String.fromCharCode(176);
    }
  }

  setCloudLayer(i: number) {
    var parsedMetar = parseMETAR(this.metar.metar);
    try {
      if(parsedMetar.clouds[i].abbreviation === "CLR") {return "";} else {
        this.addClassesToId("weather-"+i, ["clouds-"+this.theTime+"-"+parsedMetar.clouds[i].abbreviation]);
        return "<div><small>" + parsedMetar.clouds[i].altitude + "</small></div><div class='float-right'<small class='float-right'>" + parsedMetar.clouds[i].meaning + "</small></div>";
      }
    }catch(TypeError){
      return "";
    }
  }

  contentReactRender(thetime: string) {
    if(thetime === "night" || thetime === "sunset"){
      this.addClassesToClass("content-react", ["bg-dark", "text-white"]);
    }
  }

  convert(degree,value) {
    var x;
    if (degree == "C") {
      x = value * 9 / 5 + 32;
    } else {
      x = (value -32) * 5 / 9;
    }
    return Math.round(x);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BoardComponent} from './pages/board/board.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {ChoresComponent} from './pages/chores/chores.component';
import {LaundryComponent} from './pages/laundry/laundry.component';
import {NotesComponent} from './pages/notes/notes.component';
import {NewNoteComponent} from './pages/notes/new-note/new-note.component';
import {MailComponent} from './pages/mail/mail.component';
import {WeatherComponent} from './pages/weather/weather.component';

const routes: Routes = [
  {path: '', pathMatch: 'prefix', redirectTo: 'board'},
  {path: 'board', component: BoardComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'chores', component: ChoresComponent},
  {path: 'laundry', component: LaundryComponent},
  {path: 'notes', component: NotesComponent},
  {path: 'mail', component: MailComponent},
  {path: 'weather', component: WeatherComponent},
  {path: 'notes/new', component: NewNoteComponent},
  {path: 'redir/board', pathMatch: 'full', redirectTo: 'board'},
  {path: 'redir/notes', pathMatch: 'full', redirectTo: 'notes'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

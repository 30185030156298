<div class="row">
  <div class="col-md-6 float-right">
    <div class="card content-react">
      <h4 class="card-header text-center">Washer</h4>
      <div class="card-body text-center">
        <img [src]="outputWasherURL()" [classList]="isWasherRunning()" style="width: 250px; height: 250px; border-radius: 150px;">
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-6 text-center">
            <button class="btn content-react" [innerText]="calcWasherTimer()" (click)="washerTimerToggle()"># Timer</button>
          </div>
          <div class="col-md-6 text-center">
            <button class="btn btn-info btn-block" [disabled]="isWasherDisabled()" (click)="releaseWasher()">Release Washer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card content-react">
      <h4 class="card-header text-center">Dryer</h4>
      <div class="card-body text-center">
        <img [src]="outputDryerURL()" [classList]="isDryerRunning()" style="width: 250px; height: 250px; border-radius: 150px;">
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-6 text-center">
            <button class="btn content-react" [innerText]="calcDryerTimer()" (click)="dryerTimerToggle()"># Timer</button>
          </div>
          <div class="col-md-6 text-center">
            <button class="btn btn-info btn-block" [disabled]="isDryerDisabled()" (click)="releaseDryer()">Release Dryer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-3" *ngFor="let roommate of backendService.roommates">
    <div class="card content-react" [classList]="roommateBgCalc(roommate)">
      <h6 class="card-header text-center">{{roommate.name}}</h6>
      <div class="card-body">
        <button class="btn btn-link text-white text-center btn-block" style="padding-top: 0px; padding-bottom: 0px;" (click)="loadWasher(roommate)">Load Washer</button>
        <button class="btn btn-link text-white text-center btn-block" style="padding-top: 6px; padding-bottom: 0px; border-top: 1px black solid;" (click)="loadDryer(roommate)">Load Dryer</button>
      </div>
    </div>
  </div>
</div>

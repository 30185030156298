import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../../core/services/backend/backend.service';

@Component({
  selector: 'notes-mini',
  templateUrl: './notes-mini.component.html',
  styleUrls: ['./notes-mini.component.css']
})
export class NotesMiniComponent implements OnInit {

  constructor(public backendService: BackendService) { }

  ngOnInit(): void {
    this.backendService.fetchNotes();
  }

}

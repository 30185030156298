<div class="row">
  <div class="col-md-4">

  </div>
  <div class="col-md-8">
    <div class="row">
      <div class="col-md-12" *ngFor="let roommate of backendService.roommates">
        <div class="card content-react">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <img [src]="roommate.urlAvatar" style="height: 75px; width: 75px; border-radius: 25px;">
              </div>
              <div class="col-md-8">
                <button [classList]="hasMail(roommate)" [innerText]="hasMailText(roommate)" (click)="toggleMail(roommate)" style="margin-top: 15px;"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

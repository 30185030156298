<h3>New Note</h3>
<p>Please scan the QR Code below to create this note.</p>
<div *ngIf="backendService.activeEditNote != undefined">
  <qrcode [qrdata]="this.theEnvironment.baseUrl+'controllers/note/'+backendService.activeEditNote.pk+'/'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  Waiting for Completion...
</div>
<div *ngIf="backendService.activeEditNote.finished_creation === true">
  Done.
</div>

import { Injectable } from '@angular/core';
import {Roommate} from '../../interfaces/user/roommate';
import {BackendService} from '../backend/backend.service';
import * as moment from 'moment';
import {TelegramService} from '../bot/telegram.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaundryService {

  public washerRoommate: Roommate;
  public dryerRoommate: Roommate;
  public dryerRunning: boolean = false;
  public washerRunning: boolean = false;

  public onLaundry: boolean = false;

  dryerHour: string = '1';
  dryerMin: string = '30';
  washerHour: string = '1';
  washerMin: string = '15';

  areWashDoneYet: any;
  areDryDoneYet: any;

  constructor(private backendService: BackendService, private telegramService: TelegramService) {

  }

  private setupWasher(): Roommate {
    var r = this.backendService.roommates;
    for (let i = 0; i < r.length; i++) {
      if(r[i].laundryWash){
        return r[i];
      }
    }
    return null;
  }
  private setupDryer(): Roommate {
    var r = this.backendService.roommates;
    for (let i = 0; i < r.length; i++) {
      if(r[i].laundryDryer){
        return r[i];
      }
    }
    return null;
  }

  setupLaundry() {
    this.washerRoommate = this.setupWasher();
    this.dryerRoommate = this.setupDryer();
    clearInterval(this.areDryDoneYet);
    clearInterval(this.areWashDoneYet);
    this.areDryDoneYet = setInterval(() => {
      this.dryDoneFunc();
    }, 10000);
    this.areWashDoneYet = setInterval(() => {
      this.washDoneFunc();
    }, 10000);
    this.dryDoneFunc();
    this.washDoneFunc();
  }
  outputDryerURL() {
    if(this.dryerRoommate == null){
      return "/assets/img/empty.png"
    }
    return this.dryerRoommate.urlAvatar;
  }

  outputWasherURL() {
    if(this.washerRoommate == null){
      return "/assets/img/empty.png"
    }
    return this.washerRoommate.urlAvatar;
  }

  isDryerRunning(): string[] {
    if(this.dryerRoommate != null){
      if(this.dryerRoommate.laundryDryerDone != true){
        return ["spinning"];
      }else{
        return ["pulse"];
      }
    }
    return [""];
  }

  isWasherRunning(): string[] {
    if(this.washerRoommate != null){
      if(this.washerRoommate.laundryWasherDone != true){
        return ["spinning"];
      }else{
        return ["pulse"];
      }
    }
    return [""];
  }

  washDoneFunc() {
    if(this.washerRoommate != null){
      if(this.washerRoommate.laundryActive == true && this.washerRoommate.laundryWasherDone != true){
        // dryer exists and is active
        if(moment() >= moment(this.washerRoommate.laundryWashTime).add(this.washerHour, 'h').add(this.washerMin,'m')){
          this.washerRoommate.laundryWasherDone = true;
          if(environment.useTelegram && this.onLaundry) {
            var msg = "Your wash is now finished in the Washer @" + this.washerRoommate.handle + ", please come pick it up."
            var addtl = " As a heads up to everyone, this person is marked as not home right now."
            if (this.washerRoommate._check == false) {
              msg = msg + addtl
            }
            this.telegramService.sendMessage(msg);
          }
        }
      }
    }
  }

  dryDoneFunc(){
    if(this.dryerRoommate != null){
      if(this.dryerRoommate.laundryActive == true && this.dryerRoommate.laundryDryerDone != true){
        // dryer exists and is active
        if(moment() >= moment(this.dryerRoommate.laundryDryTime).add(this.dryerHour, 'h').add(this.dryerMin,'m')){
          this.dryerRoommate.laundryDryerDone = true;

          if(environment.useTelegram && this.onLaundry){
            var msg = "Your wash is now finished in the Dryer @"+this.dryerRoommate.handle+", please come pick it up."
            var addtl = " As a heads up to everyone, this person is marked as not home right now."
            if(this.dryerRoommate._check == false){
              msg = msg + addtl
            }
            this.telegramService.sendMessage(msg);
          }
        }
      }
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendService} from '../../core/services/backend/backend.service';
import {WeatherService} from '../../core/services/weather/weather.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {

  constructor(private backendService: BackendService, private weatherService: WeatherService) { }

  private resetFunction: any;

  ngOnInit(): void {
    this.weatherService.contentReactRender(this.weatherService.theTime);
    this.resetFunction = setTimeout( () => {
      this.backendService.redirectTo("/redir/board");
    }, 300000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.resetFunction);
  }

  refreshApp() {
    location.reload();
  }
}

import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../../core/services/backend/backend.service';
import {WeatherService} from '../../../core/services/weather/weather.service';
import {Roommate} from '../../../core/interfaces/user/roommate';

@Component({
  selector: 'laundry-mixin',
  templateUrl: './laundry-mixin.component.html',
  styleUrls: ['./laundry-mixin.component.css']
})
export class LaundryMixinComponent implements OnInit {

  constructor(public backendService: BackendService, public weatherService: WeatherService) { }

  ngOnInit(): void {
  }

  laundryStatement(roommate: Roommate): string {
    var s = "";
    if(roommate.laundryWash === true){
      var s = "Washer";
    }
    if(roommate.laundryDryer === true && s != ""){
      var s = "Washer and Dryer"
    }else if(roommate.laundryDryer === true){
      var s = "Dryer";
    }
    return s;

  }
}

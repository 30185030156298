import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../core/services/backend/backend.service';
import {WeatherService} from '../../core/services/weather/weather.service';
import {TelegramService} from '../../core/services/bot/telegram.service';
import {RestService} from '../../core/services/rest/rest.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {

  constructor(public backendService: BackendService,
              public weatherService: WeatherService,
              public botService: TelegramService,
              public restService: RestService) {

  }

  ngOnInit(): void {
    var x = setTimeout( () => {
      this.weatherService.contentReactRender(this.weatherService.theTime);
    }, 150);
  }

}

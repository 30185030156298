import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Message} from '../../interfaces/telegram/message';

@Injectable({
  providedIn: 'root'
})
export class TelegramService {

  apiKey: string;
  group: string;

  constructor(private http: HttpClient) {
    if(environment.useTelegram === true){
      this.apiKey = environment.telegramBotToken;
      this.group = environment.telegramGroupChatId;
    }
  }

  sendMessage(message: string, group: string = this.group){
    if(environment.useTelegram){
      let x: Message = {
        group: group,
        message: message
      }
      this.http.post(environment.baseUrl+"telegram/post/", x)
        .subscribe(data => {
          return true;
        })
    }
  }


}

import { Component, OnInit } from '@angular/core';
import {Chore} from '../../core/interfaces/chore/chore';
import {RestService} from '../../core/services/rest/rest.service';
import {BackendService} from '../../core/services/backend/backend.service';
import {WeatherService} from '../../core/services/weather/weather.service';
import {Roommate} from '../../core/interfaces/user/roommate';

@Component({
  selector: 'app-chores',
  templateUrl: './chores.component.html',
  styleUrls: ['./chores.component.css']
})
export class ChoresComponent implements OnInit {

  chores: Chore[] = [];

  viewChores: Chore[] = [];

  selectRoommate: Roommate[] = [];

  constructor(
    private rest: RestService,
    public backend: BackendService,
    private weatherService: WeatherService
  ) {

}

  ngOnInit(): void {
    this.loadChores();
    this.weatherService.contentReactRender(this.weatherService.theTime);
    var func = setTimeout( () => {
      this.weatherService.contentReactRender(this.weatherService.theTime);
    }, 100);
  }

  loadChores() {
    this.rest.get('chore/')
      .subscribe(
        (res) => {
          this.chores = res;
          this.weatherService.contentReactRender(this.weatherService.theTime);
          this.viewChore();
        }
      )
  }

  viewChore(pk: number = -1, roommate: Roommate = null){
    if(pk === -1){
      this.viewChores = [];
      for (let i = 0; i < this.chores.length; i++) {
        if(this.chores[i].user === undefined){
          this.viewChores.push(this.chores[i]);
        }
      }
      this.selectRoommate = [];
      return true;
    }else{
      for (let i = 0; i < this.chores.length; i++) {
        if(this.chores[i].user.pk === pk){
          this.viewChores.push(this.chores[i]);
        }
      }
    }
    if(roommate != null){
      this.selectRoommate = [];
      this.selectRoommate.push(roommate);
    }


  }

}

import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../../core/services/backend/backend.service';
import {WeatherService} from '../../../core/services/weather/weather.service';
import {Roommate} from '../../../core/interfaces/user/roommate';

@Component({
  selector: 'board-mini',
  templateUrl: './board-mini.component.html',
  styleUrls: ['./board-mini.component.css']
})
export class BoardMiniComponent implements OnInit {

  constructor(public backendService: BackendService, public weatherService: WeatherService) { }

  ngOnInit(): void {
  }

  roommateBgCalc(roommate: Roommate): string {
    if(roommate._check === true){
      return "bg-success"
    }else{
      return "bg-danger"
    }
  }
}
